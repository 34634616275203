/** @jsx jsx */
import { jsx } from 'theme-ui'
import logo from '../images/site/Scala-Center-horizontal.png'

import React from 'react'
import Header from '../sections/Header/Header'
import Footer from '../sections/Footer/Footer'
import {
  Helmet,
  Container,
  H3,
  H1,
  H4
} from 'gatsby-theme-landmarks-core'

const ThankYou = props => {
  return (
    <>
      <Helmet>
          <title>
            Solicitarea a fost trimisa. Va multumim!
          </title>
        <meta
          name="title"
          content="Scala Frunzisului | Succes"
        />
        <meta
          name="description"
          content="Thank You Page"
        />
      </Helmet>
      <Header
       logoSrc={logo}
       logoAlt="Scala Frunzisului"
      />

      <Container
        sx={{
          pt: '200px',
          pl: 4,
          pr: 4,
          pb: '250px'
        }}
      >
        <div
          sx={{
            px: [0, 0, 7]
          }}
        >
            <H1>
            Succes
            </H1>
          <H3>
          Solicitarea a fost trimisa. Va multumim!
          </H3>
          <H4>
              Un agent o sa va contacteze in cel mai scurt timp.
          </H4>
       </div>
        <br />
      </Container>
      <Footer/>
    </>
  )
}

export default ThankYou
